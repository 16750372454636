<template>
  <div>
    <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deletePage"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        v-if="!contentLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(viewContent)>
          <div>
            <router-link to="">
              View
            </router-link>
          </div>
        </template>

        <template #cell(updatedDate)="data">
          <b-badge pill class="text-capitalize">
            {{ data.item.updatedDate | formatDate }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            v-if="checkAccess.Edit || checkAccess.delete"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.Edit
              "
              :to="{
                name: 'static-content-edit',
                params: { id: data.item.id }
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="
                checkLoginRole() === RoleEnum.SuperAdmin
                  ? true
                  : checkAccess.delete
              "
              @click="openModal(data.item.id, data.item.pageType)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <Loader v-if="contentLoading" />
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing {{ totalCurrency >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalCurrency ? totalCurrency : endIndex }} of
              {{ totalCurrency }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalCurrency"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
  BBadge
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  perPageOptions,
  perPage,
  currentPage,
  deleteConfirmMsg,
  resConditionCheck,
  RoleEnum
} from "@/helpers/constant";
import contentAdminService from "@/services/content-admin/contentAdmin.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BBadge,
    ConfirmDelete,
    Loader
  },

  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      perPageOptions,
      perPage,
      RoleEnum,
      checkLoginRole,
      checkAccess: {},
      totalCurrency: 0,
      isSortDirDesc: true,
      currentPage,
      items: [],
      deleteMsg: "",
      titleMsg: "",
      startIndex: 0,
      endIndex: 0,
      errorData: "",
      contentId: "",
      tableColumns: [
        { key: "pageType", sortable: true },
        { key: "viewContent" },
        { key: "updatedDate" },
        { key: "actions" }
      ]
    };
  },

  watch: {
    currentPage: {
      handler() {
        this.getStaticListing();
      }
    },

    perPage: {
      handler() {
        this.currentPage = 1;
        this.getStaticListing();
      }
    },

    sortBy: {
      handler() {
        this.getStaticListing();
      }
    },

    searchQuery: {
      handler() {
        this.getStaticListing();
      }
    },

    isSortDirDesc: {
      handler() {
        this.getStaticListing();
      }
    }
  },
  mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name);
    this.getStaticListing();
    if (
      this.checkLoginRole() !== RoleEnum.SuperAdmin
      && !this.checkAccess.Edit
      && !this.checkAccess.delete
    ) {
      this.tableColumns = [
        { key: "pageType", sortable: true },
        { key: "viewContent" },
        { key: "updatedDate" }
      ];
    }
  },

  methods: {
    getStaticListing() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };
      contentAdminService
        .getStaticContentListing(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalCurrency = res.data.count;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    openModal(id, page) {
      this.titleMsg = deleteConfirmMsg;
      this.deleteMsg = `Are you sure you want to delete ${page} ?`;
      this.contentId = id;
      this.$refs.modal.show();
    },

    deletePage(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.modal.loading = true;
      if (this.contentId) {
        contentAdminService
          .deletePage(this.contentId)
          .then(res => {
            if (resConditionCheck(res.status)) {
              this.$refs.modal.loading = false;
              this.$refs.modal.toggleModal = false;
              this.items = this.items.filter(
                item => item.id !== this.contentId
              );
              if (this.items.length === 0 && this.currentPage !== 1) {
                this.currentPage -= 1;
                this.getStaticListing();
              } else {
                this.getStaticListing();
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success"
                }
              });
            }
          })
          .catch(error => {
            this.$refs.modal.loading = false;
            this.$refs.modal.toggleModal = false;
            const errorData = errorResponseHandler(error);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
